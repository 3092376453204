

.page-titles {
    padding: 0.9375rem 1.25rem;
    background: $white;
    margin-bottom: 30px;
    border-radius: 5px;
    @at-root [data-theme-version="dark"] & {
        background: $dark-card;
    }

    @include respond('phone-land') {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 15px;
        padding: 15px 0;
    }

    .justify-content-sm-end{
        align-items: center;
    }

    h4 {
        margin-bottom: 0;
        margin-top: 0;
        color: $primary;
        font-size: 1.25rem;
        span {
            font-size: 0.875rem;
            font-weight: 400;
        }
    }
    .breadcrumb {
        margin-bottom: 0;
        padding: 0;
        background: transparent;
        // font-size: 0.875rem;
        li {
            margin-top: 0;
            margin-bottom: 0;

            a{
               color: $l-ctl; 

            }

            &.active{
                color: #555555;
            }
        }
        
        .breadcrumb-item + .breadcrumb-item {
            &:before {
                content: "\e606";
                font-family: 'simple-line-icons';
                // font-weight: 4;
                font-size: 10px;
				line-height: 26px;
            }
        }

        &-datepicker{
            font-size: 0.75rem;
            color: $muted;

            &__icon{
                font-size: 0.875rem;
            }
        }
    }

    .breadcrumb-widget{
        .border-dark{
            border-color: $gray-300!important;
        }
        h4{
            color: $strong;
            font-weight: 600;
        }
        @include respond('phone') {
            text-align: left!important;
            margin-bottom: 0.9375rem;
        }
    }
    
}

