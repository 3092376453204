.navbar-custom.navbar-white {
	background-color: #f8faff !important;
	padding: 10px 0;
	box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
}



#registerBtn,#loginBtn{
    background-color: #3F9AE0;
}
#registerBtn:hover{
    background-color: #0a58ca;
}
#loginBtn:hover{
    background-color: #0a58ca;
}

#home{
    position: relative;
    background-repeat: no-repeat;
background-size: cover;
background-position: center;
padding-bottom: 25px;
padding-top: 220px;
}
#home::before{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: #0000004f; */
    background-image: linear-gradient(#ffffff69, #0000004f);
    z-index: 0;
}

.litle-descr,.home-small-title,.home-title{
    color:#ffffff;
}

.bg-overlay {
    background-image: linear-gradient(#ffffff69, #0000004f);
	/* background: linear-gradient(to right, #363795, #005C97); */
	opacity: 0.95;
	position: absolute;
	height: 100%;
	width: 100%;
	right: 0;
	bottom: 0;
	left: 0;
	top: 0;
}

.footer2 {
	padding: 50px 0;
	background-color: #272a33;
}

.footer-alt {
	padding: 15px 0;
	background-color: #2e313a;
}
.footer2 h4 {
	color: #ffffff;
}

.footer2 .footer-list li a {
	color: #98a0aa;
	padding-top: 8px;
	display: inline-block;
	font-size: 14px;
	transition: all 0.3s ease-in-out;
    text-decoration: none;

}
.social-icon2 {
	border: 2px solid #40434e;
	border-radius: 50%;
	color: #6b707d !important;
	width: 36px;
	height: 36px;
	display: block;
	font-size: -42px;
	line-height: 32px;
	text-align: center;
}

.jobTitle,.jobDetail,.jobDate{
    color: #686868;
}

.chromeCard{
    width: 100%;
    height: 100%;
    margin:0;
}

.job-header {
	background-color: #868686;
    /* height: 10rem; */
}

.header-section {
	padding-top: 12rem;
}

.jobtitle-h {
	text-align: center;
	padding: 1rem 0rem;
    color: #313131;
}
.dendidate{
	font-size: 1.2rem;
}

.headerDiv {
    display: flex;
    justify-content: space-between;
}

.jobRow .col-md-12:last-child{
	margin-bottom: 3rem !important;
}


#preloader-home{
    /* position: fixed; */
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #fff;
}
.list-style-square{
	list-style:square;
}
.required-job-ast{
	color:red;
}
