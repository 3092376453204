.form-control.input-search{
	height:38px;
	border-radius:8px;
	margin-top:8px;
	margin-bottom:8px;
}
.input-search{
	height:38px;
	border-radius:8px;
	margin-top:8px;
	margin-bottom:8px;
	border:1px solid #eee;
	padding: 0 8px;
}


.filtering-table thead th:first-child{
	width:70px;
}
.table tfoot tr {
    border-bottom-width: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    border-color: #EEEEEE;
}

.filter-pagination{
	display: inline-block;
    background: #f9f9f9;
    border-radius: 10px;
    padding: 5px 5px;
}
.filter-pagination .previous-button,
.filter-pagination .next-button{
    font-size: 16px;
    border: 0 !important;
    width: auto;
	padding: 14px 20px;
	margin: 0 5px;
    border-radius: 10px;
    display: inline-block;
    background-color: #fff;
	color: #7e7e7e;
}
.filter-pagination button:hover{
	background:	#3F9AE0;
	color:#fff;
}
.filter-pagination button[disabled]{
	opacity:0.5;
	cursor: default;
}
.filter-pagination button[disabled]:hover{
	background:#2f363e;
	opacity:0.5;
	color:#fff;
}
.table-index input[type=number] {
    background:#f9f9f9 !important;
    color: #7e7e7e;
    width: 70px;
    border: 0;
    background-color: #f8f8f8;
    padding: 6px 0 6px 10px;
}
@media only screen and (max-width: 575px) {

    .filter-pagination .previous-button,
    .filter-pagination .next-button{
        padding:8px 10px;
        font-size:14px;
    }
}


.checklist-btn{
    max-width: 250px;
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 7px 27px 7px;
  outline: 0;
  border: 1px solid #5e5e5e;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0) !important;
  color: #5e5e5e !important;
}
.checklist-btn::after {
    content: "";
    border: 1px solid #5e5e5e;
    /* background-color: #ffe54c; */
    background-color: rgba(0, 0, 0, 0) !important;
    width: 100%;
    z-index: 0;
    position: absolute;
    height: 100%;
    top: 3px;
    left: 3px;
    transition: 0.1s;
  }

  .checklist-btn:hover::after {
    top: 0px;
    left: 0px;
    border: 0px solid #5e5e5e !important;
  }


  .apply-job-btn{
    max-width: 250px;
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 7px 27px 7px;
  outline: 0;
  border: 1px solid #5e5e5e;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0) !important;
  color: #5e5e5e !important;
}
.apply-job-btn::after {
    content: "";
    border: 1px solid #5e5e5e;
    /* background-color: #ffe54c; */
    background-color: rgba(0, 0, 0, 0) !important;
    width: 100%;
    z-index: 0;
    position: absolute;
    height: 100%;
    top: 3px;
    left: 3px;
    transition: 0.1s;
  }

  .apply-job-btn:hover::after {
    top: 0px;
    left: 0px;
    border: 0px solid #5e5e5e !important;
  }